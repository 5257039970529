import { PermissionEnum } from '~/packages/user/enums/permission.enums';

export default defineNuxtRouteMiddleware(() => {
  if (useNuxtApp().$user.session.hasAnalyticsAccess) {
    if (userCan(PermissionEnum.INTEGRATION_VIEW)) {
      return navigateTo('/integrations');
    }

    if (userCan(PermissionEnum.ATTRITION_HEATMAP_VIEW)) {
      return navigateTo('/attrition/heatmap');
    }

    if (userCan(PermissionEnum.PRODUCTIVITY_HEATMAP_VIEW)) {
      return navigateTo('/productivity/heatmap');
    }
  }
});
